import axios from 'axios'
import { mapState, mapActions } from 'pinia'
import { useAppStore } from '@/pages/home/homeStore'

const baseUrl = import.meta.env.VITE_AXIOS_URL
const Request = axios.create({
  baseURL: baseUrl,
  timeout: 50000, // 設定請求最大時長
  headers: {
    'Content-Type': 'multipart/form-data',
    osType: 'web',
    language: 'zhtw',
  },
  // withCredentials: true,
})

// 請求攔截器
Request.interceptors.request.use(
  config => {
    const accessToken =
      localStorage.getItem('accessToken') || import.meta.env.VITE_GUEST_TOKEN
    config.headers.Authorization = `Bearer ${accessToken}`
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// 響應攔截器
Request.interceptors.response.use(
  response => {
    if (response.status === 200) {
      if (response.data.ApiStatus) {
        response.data.apiStatus = response.data.ApiStatus
      }
      // 003登入過期
      if (response.data.apiStatus == '003') {
        console.log('登入過期')
        const refreshToken = localStorage.getItem('refreshToken')
        const memberId = localStorage.getItem('memberId')
        if (refreshToken && memberId) {
          return refreshTokenAndRetry(response.config)
        } else {
          // window.location.href = './'
          return Promise.reject(response) // 返回 Promise.reject 終止後續調用執行
        }
      }
      // 和泰會員中心通知刪除會員
      else if (response.data.apiStatus == '012') {
        useAppStore().$state.isErrorCode_012 = true
      }
      // 和泰會員中心通知刪除會員(解綁)
      else if (response.data.apiStatus == '013') {
        useAppStore().$state.isErrorCode_013 = true
      }
      // 系統維護中
      else if (response.data.apiStatus == '014') {
        useAppStore().$state.isErrorCode_014 = true
        useAppStore().$state.errorMsg014 = response.data.message
        console.log('系統維護中!', response.data.message)
      } else {
        // 測試
        // useAppStore().$state.isErrorCode_014 = true
        // useAppStore().$state.errorMsg014 =
        //   '2024-06-19 17:40:00 ~ 2024-06-19 17:45:00\n測試系統維護中\n請稍後'
        // console.log('api狀態::', response.data.message)
        return response
      }
    } else {
      return Promise.reject(response) // 返回 Promise.reject 終止後續調用執行
    }
  },
  error => {
    console.log('API Error:', error)
    return Promise.reject(error) // 返回 Promise.reject 終止後續調用執行
  }
)

// 發送 refresh token 請求，取得新的 access token 之後重新發送請求
async function refreshTokenAndRetry(config: any) {
  try {
    const refreshToken = localStorage.getItem('refreshToken')
    const memberId = localStorage.getItem('memberId')
    const response = await post('/Token/Refresh', { refreshToken, memberId })
    if (response.data.apiStatuse == '001') {
      const data = response.data.data
      localStorage.setItem('accessToken', data.accessToken)
      localStorage.setItem('refreshToken', data.refreshToken)
      localStorage.setItem('memberId', data.memberId)
      // 將原始請求的 Authorization Header 更新為新的 access token
      config.headers.Authorization = data.accessToken
      // 重新發送原始請求
      return Request(config)
    } else {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('memberId')
      return Promise.reject()
    }
  } catch (error) {
    console.error('Error refreshing token:', error)
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('memberId')
    return Promise.reject(error)
  }
}

// 封裝請求方式
// @param url 介面地址
// @param data 攜帶引數
// @param auth 是否攜帶token
// @param config 請求config
//
// get請求
export function get(
  url: string,
  data?: any,
  auth: boolean = true,
  config?: {}
): Promise<VoidFunction> {
  if (auth || config) {
    return Request.get(url, {
      params: { ...data },
      ...config,
      // headers: {
      //   Authorization: localStorage.getItem('accessToken'),
      // },
    })
  } else {
    return Request.get(url, { params: { data } })
  }
}
// post請求
export function post(
  url: string,
  data?: any,
  auth: boolean = true,
  config?: {}
) {
  if (auth || config) {
    return Request.post(url, data, config)
  } else {
    return Request.post(url, data)
  }
}
// put請求
export function put(
  url: string,
  data?: any,
  auth: boolean = true,
  config?: {}
) {
  if (auth || config) {
    return Request.put(url, data, config)
  } else {
    return Request.put(url, data)
  }
}
// delete 請求
export function del(
  url: string,
  data?: any,
  auth: boolean = true,
  config?: {}
): Promise<VoidFunction> {
  if (auth) {
    return Request.delete(url, {
      ...config,
      data: data,
    })
  } else {
    return Request.delete(url)
  }
}
// upload 請求
export function uploader(
  url: string,
  file: File,
  auth = true,
  config?: {}
): Promise<VoidFunction> {
  const params = new FormData()
  params.append('file', file)

  if (auth) {
    return Request.post(url, params, config)
  } else {
    return Request.post(url, params)
  }
}
